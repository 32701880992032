import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout"

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout className="blog blog-post">
      <article>
        <header>
          <h1>{frontmatter.title}</h1>
          <time className="pubdate" datetime={frontmatter.rawDate}>
            Published {frontmatter.date}
          </time>
        </header>

        <section
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D MMMM, YYYY")
        rawDate: date
        slug
        title
      }
    }
  }
`
